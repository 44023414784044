import axios from 'axios';
import i18next from 'i18next';
import uuid from 'react-uuid';
import { setUser } from '../../store/features/userSlice';
import { addToast } from '../features/toastSlice';

let isRefreshing = false;
let refreshSubscribers = [];

let store;

export const injectStore = _store => {
  store = _store;
};

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  timeout: 10000,
  params: {},
});

function onRrefreshed(token) {
  refreshSubscribers.map(cb => cb(token));
  refreshSubscribers = [];
}

function subscribeTokenRefresh(cb) {
  refreshSubscribers.push(cb);
}

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const { response, code } = error || {};
    const { data } = response || {};
    const { errorDescription, error_description } = data || {};
    const commonError = errorDescription || error_description;
    if (code === 'ECONNABORTED' || code === 'ERR_NETWORK') {
      const { dispatch } = store;
      dispatch(
        addToast({
          error: true,
          text: i18next.t('OFFLINE_OR_POOR_WIFI'),
          id: uuid(),
        }),
      );
    } else if (
      commonError === 'User is inactive' ||
      commonError === 'Invalid jwt token' ||
      commonError === "User or Role doesn't exist"
    ) {
      localStorage.removeItem('user');
      localStorage.removeItem('current-organisation');
      localStorage.removeItem('sidebar-locked');
      localStorage.removeItem('selected-organisation');
      localStorage.removeItem('l-filter');
      localStorage.removeItem('r-filter');
      localStorage.removeItem('current-customers-page');
      window.location.reload();
    } else if (commonError === 'Expired jwt token' || commonError === 'User permissions expired') {
      if (!isRefreshing) {
        isRefreshing = true;
        getUserData(true)
          .then(newUser => {
            isRefreshing = false;
            onRrefreshed(newUser.access_token);
          })
          .catch(e => {
            localStorage.removeItem('user');
            localStorage.removeItem('current-organisation');
            localStorage.removeItem('sidebar-locked');
            localStorage.removeItem('selected-organisation');
            localStorage.removeItem('l-filter');
            localStorage.removeItem('r-filter');
            localStorage.removeItem('current-customers-page');
            window.location.reload();
          });
      }
      const retryOrigReq = new Promise((resolve, reject) => {
        subscribeTokenRefresh(token => {
          error.config.headers.Authorization = 'Bearer ' + token;
          resolve(instance.request(error.config));
        });
      });
      return retryOrigReq;
    }
    return Promise.reject(error);
  },
);

instance.interceptors.request.use(
  async function (config) {
    try {
      config.headers.Authorization = 'Bearer ' + (await getAccessToken());
    } catch (e) {
      localStorage.removeItem('user');
      localStorage.removeItem('current-organisation');
      localStorage.removeItem('sidebar-locked');
      localStorage.removeItem('selected-organisation');
      localStorage.removeItem('l-filter');
      localStorage.removeItem('r-filter');
      localStorage.removeItem('current-customers-page');
      window.location.reload();
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

const getUserData = async (ignore = false) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const api_tokens = user?.api_tokens || {};
  if (api_tokens.exp - 10000 < new Date().getTime() || ignore) {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/authentication/refresh?refresh_token=${api_tokens.refresh_token}`,
      {},
    );
    const newUser = { ...user, api_tokens: data };
    localStorage.setItem('user', JSON.stringify(newUser));
    const { dispatch } = store;
    dispatch(setUser(newUser));
    return newUser;
  } else return user;
};

const getAccessToken = async (ignore = false) => {
  const user = await getUserData(ignore);
  const api_tokens = user?.api_tokens || {};
  return api_tokens.access_token;
};

export const cloudinaryApi = axios.create({
  baseURL: `https://api.cloudinary.com/v1_1`,
  timeout: 20000,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'multipart/form-data',
  },
});

export default instance;
