import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import { ReactComponent as BackIcon } from '../../assets/images/arrow-left.svg';
import { ReactComponent as EditIcon } from '../../assets/images/edit.svg';
import { ReactComponent as Loading } from '../../assets/images/loading.svg';
import { ReactComponent as MailIcon } from '../../assets/images/mail.svg';
import { ReactComponent as MobileIcon } from '../../assets/images/mobile.svg';
import { ReactComponent as PlusIcon } from '../../assets/images/plus.svg';
import { ReactComponent as TrashIcon } from '../../assets/images/trash-red.svg';
import Button from '../../components/Elements/button/button';
import Status from '../../components/Elements/status';
import UserHeader from '../../components/UserHeader';
import UpdateAction from '../../components/common/update-action';
import { setItemInLocalStorage } from '../../helpers/localstorage';
import { getUserInitials } from '../../helpers/utils';
import { createFileUpload } from '../../store/features/fileuploadSlice';
import { addToast } from '../../store/features/toastSlice';
import { getProfileDetails, setUser, updateProfileData } from '../../store/features/userSlice';
import { ProfileWrapper } from '../../styles/pages/profile.styled';

const Profile = () => {
  const { t } = useTranslation();
  const inputRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { profileData, userDetails, user } = useSelector(state => state.user);
  const [imageData, setImageData] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [isProfileUploading, setIsProfileUploading] = useState(false);
  const [isProfileDeleting, setIsProfileDeleting] = useState(false);
  const [triggerUpdate, setTriggerUpdate] = useState(false);

  useEffect(() => {
    dispatch(getProfileDetails({ id: userDetails?.id }))
      .then(response => {
        const splitedUsername = response?.name.split(' ');
        const splitedVal = (splitedUsername || [])?.splice(1, splitedUsername.length);
        const updatedUser = {
          ...user,
          user: {
            ...user?.user,
            forename: splitedUsername[0] || user?.user?.forename,
            surname: splitedVal.map(data => data).join(' ') || user?.user?.surname,
            email: response?.email || user?.user?.email,
          },
        };
        dispatch(setUser(updatedUser));
        setItemInLocalStorage('user', updatedUser);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    setImageData({ url: profileData?.avatar?.url });
    dispatch(setUser({ ...user, user: { ...user.user, avatar_url: profileData?.avatar?.url } }));
  }, [profileData?.avatar?.url]);

  const onUpdate = async (field = '', imgData = '') => {
    try {
      const country_code = profileData?.phone?.startsWith('+44') ? '+44' : '+1';
      const phone = profileData?.phone?.replaceAll(country_code, '').replaceAll(' ', '').replaceAll('-', '');
      let updatedDetails = {
        title: '',
        forename: profileData?.name.split(' ')[0],
        surname: profileData?.name.split(' ')[1],
        email: profileData?.email,
        phone: phone,
        timezone: profileData?.timezone,
        language: profileData?.language,
        country_code: country_code,
      };
      if (field === 'img' && imgData) {
        setIsProfileUploading(true);
        const imageResponse = await dispatch(createFileUpload({ fileData: imgData, folderName: 'avatar' }));
        setIsProfileUploading(false);

        updatedDetails = {
          ...updatedDetails,
          avatar: { id: imageResponse?.length ? imageResponse[0]?.data?.media_id : '' },
        };
      } else if (field === 'img' && !imgData) {
        setIsProfileDeleting(true);
        updatedDetails = { ...updatedDetails, avatar: { id: '' } };
      }
      dispatch(updateProfileData({ request: updatedDetails }))
        .then(async () => {
          await dispatch(getProfileDetails({ id: userDetails?.id }));
          if (field === 'img' && !imgData) {
            setIsProfileDeleting(false);
            // setIsEdit(false);
          } else {
            setIsEdit(false);
          }
        })
        .catch(e => {
          setIsProfileUploading(false);
          setIsProfileDeleting(false);
          // setIsEdit(false);
          dispatch(addToast({ error: true, text: 'Error while updating details', id: uuid() }));
        });
    } catch (error) {
      dispatch(addToast({ error: true, text: 'Error while uploding image', id: uuid() }));
      setIsProfileUploading(false);
      return;
    }
  };

  const emptyImageContent = (
    <div
      className={`img-container flex items-center justify-center radius-4 empty-img-content cursor pxy-2 ${
        isEdit && 'on-edit'
      }`}>
      <input
        className="display-none"
        type="file"
        ref={inputRef}
        onChange={e => {
          setImageData({ ...imageData, img: e.target.files[0], url: window.URL.createObjectURL(e.target.files[0]) });
          // onUpdate('img', e.target.files[0]);
        }}
      />
      <div
        className="user-icon flex items-center justify-center empty-img nots-user-image"
        style={{ width: '140px', height: 'px' }}>
        <span className="font-60 bold-text grey-text">{getUserInitials(user?.user || {})}</span>
      </div>
      <div className="add-img-btn radius-2 flex items-center justify-center" onClick={() => inputRef.current.click()}>
        {isProfileUploading ? (
          <Loading />
        ) : (
          <div className="plus-icon-background">
            <PlusIcon />
          </div>
        )}
      </div>
    </div>
  );

  const imageContent = (
    <div
      className={`img-container flex items-center justify-center radius-4 cursor img-data-wrapper ${
        isEdit && 'on-edit'
      }`}>
      <div className="delete-btn absolute">
        <div>
          <div
            className="add-img-btn radius-2 flex items-center justify-center"
            onClick={() => {
              inputRef.current.click();
            }}>
            {isProfileUploading ? (
              <Loading />
            ) : (
              <div
                className="plus-icon-background"
                onClick={() => {
                  onUpdate('img', '');
                  setImageData('');
                }}>
                <TrashIcon />
              </div>
            )}
          </div>
        </div>
      </div>
      <img
        className="flex items-center justify-center radius-2"
        src={imageData?.url ? imageData?.url : profileData?.avatar?.url}
        style={{ objectFit: 'cover', height: '140px', width: '140px' }}
      />
    </div>
  );

  const showAdminAccessError = () => {
    dispatch(
      addToast({
        error: true,
        title: 'Please contact administrator',
        text: 'Contact your administrator to update',
        id: uuid(),
      }),
    );
  };

  const onCancelUpdate = () => {
    if (imageData?.img) {
      setIsProfileDeleting(true);
      onUpdate('img', '');
      setIsEdit(false);
    } else {
      setIsEdit(false);
    }
  };
  const onImageUpdate = () => {
    if (imageData?.img) {
      onUpdate('img', imageData?.img);
      // setIsEdit(false);
    } else {
      setIsEdit(false);
    }
  };

  return (
    <ProfileWrapper className="flex-column w-full desktop-view">
      <UserHeader />
      <div className="flex-column items-center flex-1 w-full">
        <div className="flex w-full profile-base-wrapper">
          <div className="flex h-fit-content items-center col-gap-2">
            <div
              className="flex items-center justify-center cursor back-icon-container radius-4"
              onClick={() => navigate('/forms')}>
              <BackIcon className="back-icon" height={16} width={16} />
            </div>
            <label className="medium-text font-20">{t('MY_PROFILE')}</label>
          </div>
        </div>
        <div className="container flex-column flex-1">
          <div className="flex items-center relative user-details w-full">
            <div className="flex items-center col-gap-6 flex-1">
              <div className="relative">
                {imageData?.url ? imageContent : profileData?.avatar?.url ? imageContent : emptyImageContent}
              </div>
              {isEdit && (
                <div className="updateAction">
                  <UpdateAction
                    onCancel={onCancelUpdate}
                    onUpdate={onImageUpdate}
                    disable={isProfileDeleting || isProfileUploading}
                  />
                </div>
              )}
              <div className="flex-column flex-1 row-gap-1 mt-6">
                <div className="flex items-center col-gap-4 w-full flex-1">
                  <label className="semibold-text font-24 one-line">{profileData?.name}</label>
                  <div className="desktop-status">
                    <Status withDot={true} status={profileData?.status} />
                  </div>
                </div>
                <div className="flex items-center col-gap-4 w-full flex-1">
                  <label className="regular-text grey-text">{profileData?.role?.name}</label>
                  <div className="mobile-status">
                    <Status withDot={true} status={profileData?.status} />
                  </div>
                </div>
              </div>
            </div>

            <div className="desktop-edit-btn">
              <Button
                label="Edit"
                className="default-secondary mt-4"
                width="90px"
                icon={<EditIcon height={16} width={16} />}
                size="medium"
                borderRadius="12px"
                labelClassName="regular-text"
                onClick={() => setIsEdit(!isEdit)}
              />
            </div>
          </div>

          <div className="mobile-edit-btn">
            <Button
              label="Edit"
              className="default-secondary"
              width="90px"
              icon={<EditIcon height={16} width={16} />}
              size="medium"
              borderRadius="12px"
              labelClassName="regular-text"
              onClick={() => setIsEdit(!isEdit)}
            />
          </div>
          <div className="details-box-wrapper">
            <div className="flex-column row-gap-4 radius-4 px-6 pt-4 pb-6 details-box" onClick={showAdminAccessError}>
              <label className="semibold-text font-14 grey-text">EMAIL</label>
              <div className="flex items-center w-full col-gap-2">
                <MailIcon height={16} width={16} />
                <label className="regular-text font-16 flex-1 one-line">{profileData?.email}</label>
              </div>
            </div>
            <div className="flex-column row-gap-4 radius-4 px-6 pt-4 pb-6 details-box" onClick={showAdminAccessError}>
              <label className="semibold-text font-14 grey-text">PHONE NUMBER</label>
              <div className="flex items-center w-full col-gap-2">
                <MobileIcon height={16} width={16} />
                <label className="regular-text font-16 flex-1 one-line">
                  {profileData?.phone ? `${profileData?.phone}` : ''}
                </label>
              </div>
            </div>

            <div className="flex-column row-gap-4 radius-4 pxy-4 details-box" onClick={showAdminAccessError}>
              <label className="semibold-text font-14 grey-text">BADGE NUMBER</label>
              <div className="flex items-center w-full col-gap-2">
                <label className="regular-text font-16 flex-1 one-line">
                  {profileData?.badge_number ? profileData?.badge_number : ''}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ProfileWrapper>
  );
};

export default Profile;
