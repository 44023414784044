import { nanoid } from '@reduxjs/toolkit';
import moment from 'moment';
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import uuid from 'react-uuid';
import Address from '../../components/Elements/address';
import Button from '../../components/Elements/button/button';
import Checkbox from '../../components/Elements/checkbox';
import DateOfBirthDayMonthYear from '../../components/Elements/date-of-birth/DayMonthYear';
import DateSelectorElement from '../../components/Elements/date-selector';
import Dropdown from '../../components/Elements/dropdown';
import FormPhoneInput from '../../components/Elements/form-phone-input';
import HeaderElement from '../../components/Elements/header';
import ImageElement from '../../components/Elements/image-element';
import InputElement from '../../components/Elements/input';
import Loader from '../../components/Elements/loader';
import Payment from '../../components/Elements/payment';
import ProgressBar from '../../components/Elements/progress-bar';
import ProgressSteps from '../../components/Elements/progress-steps';
import Signature from '../../components/Elements/signature';
import UserHeader from '../../components/UserHeader';
import { OrganisationContext } from '../../context/organisationContext';
import { stateOptions } from '../../helpers/optionData';
import {
  getAlignment,
  getFontFamily,
  getUserData,
  isDateOfBirthInRange,
  isValidDateOfBirth,
} from '../../helpers/utils';
import { useQuery } from '../../hooks/useQuery';
import {
  createContact,
  createFormSessionForAuth,
  createFormSessionForLaunch,
  getFormForAuth,
  getFormForLaunch,
  getFormPanelForAuth,
  getFormPanelForLaunch,
  getFormWidgetForAuth,
  getFormWidgetForLaunch,
  setForm,
  setFormPanel,
  setFormSession,
  setFormSessionData,
  setFormWidget,
  setSelectedFormPanel,
  submitFormSessionDataForAuth,
  submitFormSessionDataForLaunch,
  updateFormSessionForAuth,
  updateFormSessionForLaunch,
} from '../../store/features/formSlice';
import { addToast } from '../../store/features/toastSlice';
import { FormDetailWrapper } from '../../styles/pages/form-detail.styled';

const apiForLaunch = {
  createFormSession: createFormSessionForLaunch,
  getForm: getFormForLaunch,
  getFormPanel: getFormPanelForLaunch,
  getFormWidget: getFormWidgetForLaunch,
  submitFormSessionData: submitFormSessionDataForLaunch,
  updateFormSession: updateFormSessionForLaunch,
};

const apiForAuth = {
  createFormSession: createFormSessionForAuth,
  getForm: getFormForAuth,
  getFormPanel: getFormPanelForAuth,
  getFormWidget: getFormWidgetForAuth,
  submitFormSessionData: submitFormSessionDataForAuth,
  updateFormSession: updateFormSessionForAuth,
};

const FormDetail = ({ fromLaunch = false }) => {
  const { id } = useParams();
  const query = useQuery();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setModal, modal } = useContext(OrganisationContext);

  const apiToUse = fromLaunch ? apiForLaunch : apiForAuth;

  const { form, formPanel, formWidget, selectedFormPanel, formSession, formSessionData } = useSelector(
    state => state.forms,
  );
  const { user } = useSelector(state => state.user);
  const { user: userData } = user || {};

  const { api_key } = form || {};

  const onReloadPage = () => {
    setModal({
      type: 'reload-page',
      content: { top: true },
    });
  };

  const formLoadedFirstTime = useRef(false);
  const [formSessionLoading, setFormSessionLoading] = useState(false);

  const [values, setValues] = useState({});
  const [formLoading, setFormLoading] = useState(true);
  const [widgetsLoading, setWidgetsLoading] = useState(true);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [showRequiredError, setShowRequiredError] = useState(false);
  const [showValidDataError, setShowValidDataError] = useState(false);
  const [showAgeRangeError, setShowAgeRangeError] = useState(false);
  const [showCharacterLimitError, setShowCharacterLimitError] = useState(false);
  const [showRequireConfirmationError, setShowRequireConfirmationError] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedBillingAddress, setSelectedBillingAddress] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState({ isConfirmed: false, isCollectPayment: false });
  const [showBillingAddress, setShowBillingAddress] = useState({});
  const [selectedIntegrationInComponent, setSelectedIntegrationInComponent] = useState(null);

  const getIntegrationIdFromProductComponent = (widgets = []) => {
    const widgetComponents = widgets
      .map(w => w.components)
      .filter(x => x)
      .flat();
    const productComponents = widgetComponents.filter(c => c.component_type === 'PRODUCT');
    const productComponent =
      productComponents.find(c => c.products?.length > 0 || c.integration?.id) || productComponents?.[0] || null;
    setSelectedIntegrationInComponent(productComponent);
  };

  const getFormData = async () => {
    setFormLoading(true);
    try {
      const data = await dispatch(apiToUse.getForm({ id: id }));
      await dispatch(apiToUse.getFormPanel({ id: id, api_key: data.api_key }));
      setFormLoading(false);
      formLoadedFirstTime.current = true;
    } catch (error) {
      setFormLoading(false);
      formLoadedFirstTime.current = true;
    }
  };

  useEffect(() => {
    getFormData();

    return () => {
      formLoadedFirstTime.current = true;
      dispatch(setForm({}));
      dispatch(setFormPanel([]));
      dispatch(setFormWidget([]));
      dispatch(setSelectedFormPanel({}));
      dispatch(setFormSession({}));
      dispatch(setFormSessionData([]));
    };
  }, [id, fromLaunch]);

  useEffect(() => {
    if (selectedFormPanel?.id) {
      if (selectedFormPanel.widgets) {
        dispatch(setFormWidget(selectedFormPanel.widgets));
      } else {
        setWidgetsLoading(true);
        dispatch(apiToUse.getFormWidget({ id: selectedFormPanel?.id, api_key }))
          .then(data => {
            if (!selectedIntegrationInComponent?.integration?.id) {
              getIntegrationIdFromProductComponent(data);
            }
          })
          .catch(() => {})
          .finally(() => setWidgetsLoading(false));
      }
    } else {
      setWidgetsLoading(false);
    }
  }, [selectedFormPanel?.id]);

  const createFormSessions = () => {
    if (formSessionLoading) {
      return;
    }
    const formSessionRequest = {
      session_start: moment().unix(),
      session_end: '',
      user: fromLaunch
        ? null
        : {
            id: userData.id,
          },
      form_version: form.version,
      form: {
        id: form.id,
      },
      form_type: '',
      referred_by: query.get('referrer_id'),
    };
    setFormSessionLoading(true);
    dispatch(apiToUse.createFormSession({ request: formSessionRequest, api_key }))
      .then(() => {
        formLoadedFirstTime.current = false;
        setFormSessionLoading(false);
      })
      .catch(error => {
        const { error_code, error_description } = error?.response?.data || {};
        if (
          error_code === 'BAD_REQUEST' &&
          error_description === 'New version of this form has been published. Reload the page'
        ) {
          onReloadPage();
        } else {
          dispatch(
            addToast({ error: true, text: 'Error while creating form session, Please try again later!', id: nanoid() }),
          );
        }
        setFormSessionLoading(false);
      });
  };

  const getFormAttributeValue = field => {
    const attribute = form?.attributes?.[0] || {};
    return attribute[field] || null;
  };

  const getBillingAddressComponents = widget => {
    return widget.components?.map(c => ({
      ...c,
      id: `${c.id}-billing`,
      name: c.component_type === 'ADDRESS' ? 'Billing Address' : c.name,
      isBillingAddressComponent: true,
      lexicon: c.lexicon?.includes('contact.address')
        ? `contact.billing_address.${c.lexicon.split('contact.address.')[1]}`
        : c.lexicon,
    }));
  };

  const checkRequiredFieldError = widget => {
    const requiredComponents = widget
      .map(w => w.components)
      .flat()
      .filter(c => c.is_required && c.is_visible);
    const isError = requiredComponents.some(c =>
      c.is_autofill
        ? !autoFillLookUp(c.lexicon)
        : c.component_type === 'PHONE'
        ? !values[c.id]?.phone
        : c.component_type === 'DOB' && c.component_layout === 'DAYMONTHYEAR'
        ? !values[c.id]?.day || !values[c.id]?.month || !values[c.id]?.year
        : !values[c.id],
    );
    return isError;
  };

  const checkBillingAddressRequiredFieldError = widget => {
    const requiredComponents = widget
      .filter(w => showBillingAddress[w.id])
      .map(w => getBillingAddressComponents(w))
      .flat()
      .filter(c => c.is_required && c.is_visible);
    const isError = requiredComponents.some(c =>
      c.is_autofill
        ? !autoFillLookUp(c.lexicon)
        : c.component_type === 'PHONE'
        ? !values[c.id]?.phone
        : c.component_type === 'DOB' && c.component_layout === 'DAYMONTHYEAR'
        ? !values[c.id]?.day || !values[c.id]?.month || !values[c.id]?.year
        : !values[c.id],
    );
    return isError;
  };

  const checkConfirmFieldError = widget => {
    const requireConfirmationComponents = widget
      .map(w => w.components)
      .flat()
      .filter(c => c.requires_confirmation && c.is_visible);
    const isError = requireConfirmationComponents.some(
      c => (values[c.id] || values[`${c.id}-confirm`]) && values[c.id] !== values[`${c.id}-confirm`],
    );
    return isError;
  };

  const checkValidFieldError = widget => {
    const requiredComponents = widget
      .map(w => w.components)
      .flat()
      .filter(c => c.is_visible && c.component_type === 'DOB');
    const isError = requiredComponents.some(c =>
      values[c.id]
        ? c.component_layout === 'DAYMONTHYEAR'
          ? (!values[c.id]?.day || !values[c.id]?.month || !values[c.id]?.year) &&
            !isValidDateOfBirth(
              moment(`${values[c.id]?.day}/${values[c.id]?.month}/${values[c.id]?.year}`, 'DD/MM/YYYY').unix(),
            )
          : !isValidDateOfBirth(values[c.id])
        : false,
    );
    return isError;
  };

  const checkAgeRangeError = widget => {
    const requiredComponents = widget
      .map(w => w.components)
      .flat()
      .filter(c => c.is_visible && c.component_type === 'DOB');
    const isError = requiredComponents.some(c =>
      values[c.id]
        ? c.component_layout === 'DAYMONTHYEAR'
          ? !isDateOfBirthInRange(
              moment(`${values[c.id]?.day}/${values[c.id]?.month}/${values[c.id]?.year}`, 'DD/MM/YYYY').unix(),
              c.range,
            )
          : !isDateOfBirthInRange(values[c.id], c.range)
        : false,
    );
    return isError;
  };

  const checkCharacterLimitError = widget => {
    const componentTypeArray = ['ALPHA', 'NUMERIC', 'ALPHANUMERIC'];
    const requiredComponents = widget
      .map(w => w.components)
      .flat()
      .filter(
        c =>
          c.is_visible &&
          componentTypeArray.includes(c.component_type) &&
          (c.character_limit?.min || c.character_limit?.max),
      );
    const isError = requiredComponents.some(c => {
      const value = c.is_autofill ? !autoFillLookUp(c.lexicon) : values[c.id] || '';
      return (
        (c.character_limit?.min && value.length < c.character_limit?.min) ||
        (c.character_limit?.max && value.length > c.character_limit?.max)
      );
    });
    return isError;
  };

  const prepareSubmissionData = widget => {
    const request = widget
      .map(w => w.components)
      .flat()
      .filter(c => c.component_type !== 'PAYMENT')
      .reduce((acc, c) => {
        let joinedData = [...acc];
        if (c.component_type === 'CHOOSER') {
          joinedData = joinedData.concat([
            { ...c, lexicon: `${c.lexicon}.id`, originalLexicon: c.lexicon },
            { ...c, lexicon: `${c.lexicon}.name`, originalLexicon: c.lexicon },
          ]);
        } else {
          joinedData = [...joinedData, c];
        }
        return joinedData;
      }, [])
      .map(c => ({
        panel_widget_component: {
          id: c.id,
        },
        submitted_data: c.is_autofill
          ? autoFillLookUp(c.lexicon, widget)
          : c.component_type === 'SIGNATURE'
          ? ''
          : c.component_type === 'PRODUCT'
          ? values[c.id]
            ? values[c.id]?.product_id
            : ''
          : c.component_type === 'CHOOSER'
          ? c.lexicon === `${c.originalLexicon}.id`
            ? values[c.id]
              ? `${values[c.id]?.value}`
              : ''
            : c.lexicon === `${c.originalLexicon}.name`
            ? values[c.id]
              ? `${values[c.id]?.label}`
              : ''
            : ''
          : c.component_type === 'PHONE'
          ? values[c.id]?.phone
            ? values[c.id]?.value
            : ''
          : c.component_type === 'DOB' && c.component_layout === 'DAYMONTHYEAR'
          ? values[c.id]
            ? moment()
                .set('date', values[c.id]?.day)
                .set('month', values[c.id]?.month - 1)
                .set('year', values[c.id]?.year)
                .unix()
            : ''
          : c.component_type === 'MULTICHOICE'
          ? values[c.id]
            ? values[c.id]
            : false
          : values[c.id] || '',
        lexicon: c.lexicon,
        ...(c.component_type === 'SIGNATURE'
          ? { image: values[c.id]?.replace('data:image/png;base64,', '') || '' }
          : {}),
      }))
      .filter(data => typeof data.submitted_data === 'boolean' || data.submitted_data || data.image);
    return request;
  };

  const changeSelectedPanel = (panel, isPreviousStep = false) => {
    if (panel.is_confirmation_panel) {
      dispatch(setSelectedFormPanel(panel));
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      return;
    }
    if (!isPreviousStep && (checkRequiredFieldError(formWidget) || checkBillingAddressRequiredFieldError(formWidget))) {
      dispatch(addToast({ error: true, text: 'Please fill required fields', id: uuid() }));
      setShowRequiredError(true);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      return;
    }
    if (!isPreviousStep && checkConfirmFieldError(formWidget)) {
      dispatch(addToast({ error: true, text: 'Fields do not match', id: uuid() }));
      setShowRequireConfirmationError(true);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      return;
    }
    if (!isPreviousStep && checkValidFieldError(formWidget)) {
      dispatch(addToast({ error: true, text: 'Please fill valid data', id: uuid() }));
      setShowValidDataError(true);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      return;
    }
    if (!isPreviousStep && checkAgeRangeError(formWidget)) {
      dispatch(addToast({ error: true, text: 'Please enter age in range', id: uuid() }));
      setShowAgeRangeError(true);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      return;
    }
    if (!isPreviousStep && checkCharacterLimitError(formWidget)) {
      dispatch(addToast({ error: true, text: 'Please enter characters in range', id: uuid() }));
      setShowCharacterLimitError(true);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      return;
    }
    if (!isPreviousStep && formSession?.id) {
      const request = prepareSubmissionData(formWidget);
      setIsFormSubmitting(true);
      dispatch(apiToUse.submitFormSessionData({ id: formSession?.id, request, api_key }))
        .then(() => {
          setShowRequiredError(false);
          setShowRequireConfirmationError(false);
          dispatch(setSelectedFormPanel(panel));
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        })
        .catch(() => {
          dispatch(addToast({ error: true, text: 'Error while submitting form data, Please try again later!' }));
        })
        .finally(() => {
          setIsFormSubmitting(false);
        });
    } else {
      setShowRequiredError(false);
      setShowRequireConfirmationError(false);
      dispatch(setSelectedFormPanel(panel));
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  const onAddressUpdate = (address, widget, updateLexicon) => {
    const addressLexiconSplits = widget.components.find(c => c.component_type === 'ADDRESS').lexicon?.split('.') || [];
    const addressInitials = addressLexiconSplits.slice(0, addressLexiconSplits.length - 1).join('.');
    const addressValue = widget.components
      .filter(c =>
        updateLexicon
          ? c.lexicon === updateLexicon && c.lexicon?.includes(addressInitials)
          : c.lexicon?.includes(addressInitials),
      )
      .reduce((acc, { lexicon, id }) => {
        acc[id] =
          updateLexicon && lexicon === updateLexicon
            ? address[lexicon.split(`${addressInitials}.`)[1]] || ''
            : address[lexicon.split(`${addressInitials}.`)[1]] || '';
        return acc;
      }, {});
    if (addressInitials === 'contact.address') {
      setSelectedAddress(address);
    }
    if (addressInitials === 'contact.billing_address') {
      setSelectedBillingAddress({ id: widget.id, address: address });
    }
    return addressValue;
  };

  const prepareSubscriptionData = (widget, external_reference = '', subscription_id = '') => {
    const request = widget
      .map(w => w.components)
      .flat()
      .reduce((acc, c) => {
        let joinedData = [...acc];
        if (c.lexicon === 'contact.subscription') {
          joinedData = joinedData.concat([
            { ...c, lexicon: 'contact.subscription.id' },
            { ...c, lexicon: 'contact.subscription.name' },
            { ...c, lexicon: 'contact.subscription.external_reference' },
          ]);
        }
        return joinedData;
      }, [])
      .map(c => ({
        panel_widget_component: {
          id: c.id,
        },
        submitted_data:
          c.lexicon === 'contact.subscription.id'
            ? subscription_id
            : c.lexicon === 'contact.subscription.name'
            ? values?.[c.id]?.label
            : external_reference,
        lexicon: c.lexicon,
      }))
      .filter(data => data.submitted_data);
    return request;
  };

  const prepareContactData = contactId => {
    const allWidgets = formPanel
      .map(p => p.widgets)
      .filter(x => x)
      .flat();
    const request = allWidgets
      .map(w => w.components)
      .flat()
      .filter(c => c.lexicon === 'contact.id')
      .map(c => ({
        panel_widget_component: {
          id: c.id,
        },
        submitted_data: contactId,
        lexicon: c.lexicon,
      }))
      .filter(data => data.submitted_data);
    return request;
  };

  const onSubmitForm = async (contactId, external_reference, subscription_id, fromSubmitButton = false) => {
    if (
      fromSubmitButton &&
      (checkRequiredFieldError(formWidget) || checkBillingAddressRequiredFieldError(formWidget))
    ) {
      dispatch(addToast({ error: true, text: 'Please fill required fields' }));
      setShowRequiredError(true);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      return;
    }
    if (fromSubmitButton && checkConfirmFieldError(formWidget)) {
      dispatch(addToast({ error: true, text: 'Fields do not match' }));
      setShowRequireConfirmationError(true);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      return;
    }
    if (fromSubmitButton && checkValidFieldError(formWidget)) {
      dispatch(addToast({ error: true, text: 'Please fill valid data' }));
      setShowValidDataError(true);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      return;
    }
    if (fromSubmitButton && checkAgeRangeError(formWidget)) {
      dispatch(addToast({ error: true, text: 'Please enter age in range' }));
      setShowAgeRangeError(true);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      return;
    }
    if (fromSubmitButton && checkCharacterLimitError(formWidget)) {
      dispatch(addToast({ error: true, text: 'Please enter characters in range', id: uuid() }));
      setShowCharacterLimitError(true);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      return;
    }
    const request = prepareSubmissionData(formWidget);
    const subscriptionData = prepareSubscriptionData(formWidget, external_reference, subscription_id);
    const formEndRequest = {
      session_end: moment().unix(),
      user: fromLaunch
        ? null
        : {
            id: userData.id,
          },
    };
    setIsFormSubmitting(true);
    let contactData = [];

    if (fromSubmitButton && !contactId) {
      const newFormSessionData = [...formSessionData, ...request, ...subscriptionData];
      try {
        const contact = await dispatch(
          createContact({
            request: {
              ...getUserData(newFormSessionData, selectedAddress, selectedBillingAddress, showBillingAddress),
            },
            api_key: api_key,
          }),
        );
        contactData = prepareContactData(contact.id);
      } catch (error) {}
    } else {
      contactData = prepareContactData(contactId);
    }
    dispatch(
      apiToUse.submitFormSessionData({
        id: formSession?.id,
        request: [...request, ...subscriptionData, ...contactData],
        api_key,
      }),
    )
      .then(() => {
        dispatch(apiToUse.updateFormSession({ id: formSession?.id, request: formEndRequest, api_key }))
          .then(() => {
            setIsFormSubmitting(false);
            const confirmationPanel = formPanel.find(p => p.is_confirmation_panel);
            if (confirmationPanel) {
              changeSelectedPanel(confirmationPanel);
            } else {
              dispatch(addToast({ error: false, text: 'Form is submitted successfully' }));
              if (fromLaunch) {
                window.location.reload();
              } else {
                navigate('/forms');
              }
            }
          })
          .catch(() => {
            setIsFormSubmitting(false);
            dispatch(addToast({ error: true, text: 'Error while ending form session, Please try again later!' }));
          });
      })
      .catch(e => {
        setIsFormSubmitting(false);
        dispatch(addToast({ error: true, text: 'Error while submitting form data, Please try again later!' }));
      });
  };

  const getProductOptions = (options = []) => {
    return options
      .filter(option => option.pricing?.[0].currency_code === 'USD')
      .map(({ pricing, id, external_reference }) => ({
        label: pricing?.[0].display_as,
        value: pricing?.[0].external_reference,
        priceAmountLabel: `${pricing?.[0].currency_code === 'GBP' ? '£' : '$'}${pricing?.[0].price}`,
        amount: parseFloat(pricing?.[0].price) * 100,
        type: pricing?.[0]?.recurring_period,
        currency: pricing?.[0]?.currency_code,
        id: id,
        product_id: external_reference,
        price_id: pricing?.[0].external_reference,
      }));
  };

  const autoFillLookUpInFormData = lexicon => {
    let autoFillDataFromSession = formSessionData?.find(data => data.lexicon === lexicon) || null;
    return autoFillDataFromSession ? autoFillDataFromSession?.submitted_data || '' : '';
  };

  const autoFillLookUp = lexicon => {
    if (!fromLaunch) {
      if (lexicon === 'organizations.employee_number') {
        const { organizations } = user || {};
        return organizations?.[0]?.employee_number;
      } else if (lexicon === 'organizations.badge_number') {
        const { organizations } = user;
        return organizations?.[0]?.badge_number;
      } else if (lexicon === 'organizations.name') {
        const { organizations } = user;
        return organizations?.[0]?.name;
      } else if (lexicon === 'user.name') {
        return `${userData.forename} ${userData.surname}`;
      }
    }
    return autoFillLookUpInFormData(lexicon);
  };

  const groupByRow = components => {
    return components.reduce((acc, curr) => ({ ...acc, [curr.row]: (acc[curr.row] || []).concat(curr) }), {});
  };

  const getFormAttributFonts = () => {
    return {
      fontFamily: getFontFamily(getFormAttributeValue('font')?.style, getFormAttributeValue('font')?.name),
      font: getFormAttributeValue('font'),
    };
  };

  const renderWatermark = (panel, panelIndex, panelConfig) => {
    const formAttributes = form?.attributes?.[0];
    const { display_watermark, alignment, watermark_location, image } = formAttributes?.watermark || {};
    const { display_watermark: confirmation_panel_watermark } = formAttributes?.confirmation_panel || {};

    const panelConfigWithoutConfirmationPanel = panelConfig.filter(p => !p.is_confirmation_panel);
    if (!display_watermark) {
      return;
    }
    if (panel.is_confirmation_panel && !confirmation_panel_watermark) {
      return;
    }
    if (watermark_location === 'FIRSTPAGE' && panelIndex !== 0 && !panel.is_confirmation_panel) {
      return;
    }
    if (
      watermark_location === 'LASTPAGE' &&
      panelConfigWithoutConfirmationPanel.length - 1 !== panelIndex &&
      !panel.is_confirmation_panel
    ) {
      return;
    }
    if (!image?.image?.url) {
      return;
    }
    return (
      <div
        className={`flex my-4 mx-10 ${
          alignment === 'LEFT'
            ? 'items-start justify-start'
            : alignment === 'RIGHT'
            ? 'items-start justify-end'
            : 'items-end justify-center'
        }`}>
        <img height={64} width={200} src={image?.image?.url} alt="watermark" className="watermark" />
      </div>
    );
  };

  const productAutoFillLookUp = (component, lexicon) => {
    const products =
      selectedIntegrationInComponent && selectedIntegrationInComponent?.id !== component.id
        ? getProductOptions(selectedIntegrationInComponent?.products)
        : getProductOptions(component.products);
    const autoFillValue = autoFillLookUp(lexicon);
    return (products || []).find(p => p.product_id === autoFillValue);
  };

  const formWidgets = widget => {
    return (
      <div className="w-full">
        {Object.values(groupByRow(widget.components)).map(c => (
          <div className="flex flex-1 w-full device-scale-component">
            {c.map(
              component =>
                component.is_visible && (
                  <>
                    <div className="px-1 mt-4 w-full" style={{ flex: `1 1 calc(100%/${c.length})` }}>
                      {component.component_type === 'IMAGE' && (
                        <ImageElement
                          key={component.id}
                          src={component?.image?.image?.url}
                          alt={component.name}
                          media={component?.image?.media}
                          width={component?.image?.width}
                          height={component?.image?.height}
                        />
                      )}
                      {component.component_type === 'HEADER' && (
                        <HeaderElement
                          key={component.id}
                          name={component.name}
                          font={component.font}
                          fontFamily={getFontFamily(component?.font?.style, component?.font?.name)}
                          alignment={getAlignment(component.alignment)}
                        />
                      )}
                      {component.component_layout === 'STATE' && (
                        <Dropdown
                          {...component}
                          {...getFormAttributFonts()}
                          key={component.id}
                          is_required={component.is_required}
                          showRequiredError={showRequiredError && component.is_required}
                          name={component.name}
                          placeholder={component.label}
                          is_autofill={component.is_autofill}
                          sub_label={component.sub_label}
                          autoFillValue={autoFillLookUp(component.lexicon)}
                          options={stateOptions}
                          selectedValue={
                            values[component.id] ? stateOptions.find(s => s.value === values[component.id]) : null
                          }
                          onChange={option => {
                            setValues({ ...values, [component.id]: option?.value });
                            if (formLoadedFirstTime.current) {
                              createFormSessions();
                            }
                            if (component.isBillingAddressComponent) {
                              setSelectedBillingAddress({
                                ...selectedBillingAddress,
                                address: {
                                  ...selectedBillingAddress?.address,
                                  [component.lexicon.split('contact.billing_address.')[1]]: option?.value,
                                },
                              });
                            }
                          }}
                        />
                      )}
                      {component.component_type === 'CHOOSER' && (
                        <Dropdown
                          {...component}
                          {...getFormAttributFonts()}
                          key={component.id}
                          is_required={component.is_required}
                          showRequiredError={showRequiredError && component.is_required}
                          name={component.name}
                          placeholder={component.label}
                          is_autofill={component.is_autofill}
                          sub_label={component.sub_label}
                          autoFillValue={autoFillLookUp(component.lexicon)}
                          options={component.list?.choices?.map(c => ({ ...c, label: c.name, value: c.id }))}
                          selectedValue={values[component.id]}
                          onChange={option => {
                            setValues({ ...values, [component.id]: option });
                            if (formLoadedFirstTime.current) {
                              createFormSessions();
                            }
                            if (component.isBillingAddressComponent) {
                              setSelectedBillingAddress({
                                ...selectedBillingAddress,
                                address: {
                                  ...selectedBillingAddress?.address,
                                  [component.lexicon.split('contact.billing_address.')[1]]: option?.value,
                                },
                              });
                            }
                          }}
                        />
                      )}
                      {component.component_type === 'PHONE' && (
                        <FormPhoneInput
                          {...getFormAttributFonts()}
                          key={component.id}
                          is_required={component.is_required}
                          showRequiredError={showRequiredError && component.is_required}
                          name={component.name}
                          is_autofill={component.is_autofill}
                          autoFillValue={autoFillLookUp(component.lexicon)}
                          sub_label={component.sub_label}
                          phoneValue={values[component.id]}
                          onChange={value => {
                            setValues({ ...values, [component.id]: value });
                            if (formLoadedFirstTime.current) {
                              createFormSessions();
                            }
                            if (component.isBillingAddressComponent) {
                              setSelectedBillingAddress({
                                ...selectedBillingAddress,
                                address: {
                                  ...selectedBillingAddress?.address,
                                  [component.lexicon.split('contact.billing_address.')[1]]: value,
                                },
                              });
                            }
                          }}
                        />
                      )}
                      {(component.component_type === 'ALPHA' ||
                        component.component_type === 'NUMERIC' ||
                        component.component_type === 'ALPHANUMERIC') &&
                        component.component_layout !== 'STATE' && (
                          <div className="flex device-scale-component row-gap-4">
                            <InputElement
                              {...getFormAttributFonts()}
                              {...component}
                              showCharacterLimitError={showCharacterLimitError}
                              className="w-full"
                              key={component.id}
                              is_required={component.is_required}
                              showRequiredError={showRequiredError && component.is_required}
                              showRequireConfirmationError={
                                component.requires_confirmation &&
                                showRequireConfirmationError &&
                                values[component.id] !== values[`${component.id}-confirm`]
                              }
                              name={component.name}
                              label={component.label}
                              sub_label={component.sub_label}
                              is_autofill={component.is_autofill}
                              autoFillValue={autoFillLookUp(component.lexicon)}
                              type={component.component_type}
                              value={values[component.id]}
                              onChange={value => {
                                setValues({ ...values, [component.id]: value });
                                if (formLoadedFirstTime.current) {
                                  createFormSessions();
                                }
                                if (component.isBillingAddressComponent) {
                                  setSelectedBillingAddress({
                                    ...selectedBillingAddress,
                                    address: {
                                      ...selectedBillingAddress?.address,
                                      [component.lexicon.split('contact.billing_address.')[1]]: value,
                                    },
                                  });
                                }
                              }}
                            />
                            {component.requires_confirmation && (
                              <InputElement
                                {...getFormAttributFonts()}
                                {...component}
                                showCharacterLimitError={showCharacterLimitError}
                                className="pl-2 w-full confirm-component"
                                is_required={component.is_required}
                                showRequiredError={showRequiredError && component.is_required}
                                showRequireConfirmationError={
                                  showRequireConfirmationError &&
                                  values[component.id] !== values[`${component.id}-confirm`]
                                }
                                name={`Confirm ${component.name}`}
                                label={component.label}
                                sub_label={component.sub_label}
                                is_autofill={component.is_autofill}
                                type={component.component_type}
                                value={values[`${component.id}-confirm`]}
                                onChange={value => {
                                  setValues({ ...values, [`${component.id}-confirm`]: value });
                                  if (formLoadedFirstTime.current) {
                                    createFormSessions();
                                  }
                                }}
                              />
                            )}
                          </div>
                        )}
                      {component.component_type === 'DOB' && component.component_layout === 'DAYMONTHYEAR' && (
                        <DateOfBirthDayMonthYear
                          {...getFormAttributFonts()}
                          key={component.id}
                          is_required={component.is_required}
                          showRequiredError={showRequiredError && component.is_required}
                          showValidDataError={showValidDataError}
                          name={component.name}
                          placeholder={component.label}
                          sub_label={component.sub_label}
                          is_autofill={component.is_autofill}
                          showAgeRangeError={showAgeRangeError}
                          range={component.range}
                          autoFillValue={autoFillLookUp(component.lexicon)}
                          value={values[component.id]}
                          setValue={value => {
                            setValues({ ...values, [component.id]: value });
                            if (formLoadedFirstTime.current) {
                              createFormSessions();
                            }
                          }}
                        />
                      )}
                      {component.component_type === 'DOB' &&
                        (component.component_layout === 'DATEPICKER' || !component.component_layout) && (
                          <DateSelectorElement
                            {...getFormAttributFonts()}
                            key={component.id}
                            is_required={component.is_required}
                            showRequiredError={showRequiredError && component.is_required}
                            name={component.name}
                            placeholder={component.label}
                            sub_label={component.sub_label}
                            is_autofill={component.is_autofill}
                            autoFillValue={autoFillLookUp(component.lexicon)}
                            selectedDate={values[component.id]}
                            showAgeRangeError={showAgeRangeError}
                            range={component.range}
                            forDOB={true}
                            setSelectedDate={value => {
                              setValues({ ...values, [component.id]: value });
                              if (formLoadedFirstTime.current) {
                                createFormSessions();
                              }
                            }}
                          />
                        )}
                      {component.component_type === 'DATE' && (
                        <DateSelectorElement
                          {...getFormAttributFonts()}
                          key={component.id}
                          is_required={component.is_required}
                          showRequiredError={showRequiredError && component.is_required}
                          name={component.name}
                          placeholder={component.label}
                          sub_label={component.sub_label}
                          is_autofill={component.is_autofill}
                          autoFillValue={autoFillLookUp(component.lexicon)}
                          selectedDate={values[component.id]}
                          setSelectedDate={value => {
                            setValues({ ...values, [component.id]: value });
                            if (formLoadedFirstTime.current) {
                              createFormSessions();
                            }
                          }}
                        />
                      )}
                      {component.component_type === 'MULTICHOICE' && (
                        <Checkbox
                          {...getFormAttributFonts()}
                          key={component.id}
                          is_required={component.is_required}
                          showRequiredError={showRequiredError && component.is_required}
                          name={component.name}
                          is_autofill={component.is_autofill}
                          autoFillValue={autoFillLookUp(component.lexicon)}
                          checked={values[component.id]}
                          onChange={value => {
                            setValues({ ...values, [component.id]: value });
                            if (formLoadedFirstTime.current) {
                              createFormSessions();
                            }
                          }}
                        />
                      )}
                      {component.component_type === 'SIGNATURE' && (
                        <Signature
                          {...getFormAttributFonts()}
                          key={component.id}
                          is_required={component.is_required}
                          showRequiredError={showRequiredError && component.is_required}
                          name={component.name}
                          sub_label={component.sub_label}
                          signatureValue={values[component.id]}
                          onChange={value => {
                            setValues({ ...values, [component.id]: value });
                            if (formLoadedFirstTime.current) {
                              createFormSessions();
                            }
                          }}
                        />
                      )}
                      {component.component_type === 'ADDRESS' && (
                        <Address
                          {...getFormAttributFonts()}
                          key={component.id}
                          fromLaunch={fromLaunch}
                          api_key={api_key}
                          is_required={component.is_required}
                          showRequiredError={showRequiredError && component.is_required}
                          name={component.name}
                          sub_label={component.sub_label}
                          is_autofill={component.is_autofill}
                          autoFillValue={autoFillLookUp(component.lexicon)}
                          placeholder={component.label}
                          selectedValue={values[component.id]}
                          onAddressUpdate={(address, updateOnlyComponent) => {
                            setValues({
                              ...values,
                              ...onAddressUpdate(address, widget, updateOnlyComponent ? component.lexicon : null),
                            });
                            if (formLoadedFirstTime.current) {
                              createFormSessions();
                            }
                          }}
                        />
                      )}
                      {component.component_type === 'PRODUCT' && (
                        <Dropdown
                          {...component}
                          {...getFormAttributFonts()}
                          options={
                            selectedIntegrationInComponent && selectedIntegrationInComponent?.id !== component.id
                              ? getProductOptions(selectedIntegrationInComponent?.products)
                              : getProductOptions(component.products)
                          }
                          key={component.id}
                          is_required={component.is_required}
                          showRequiredError={showRequiredError && component.is_required}
                          name={component.name}
                          placeholder={component.label}
                          sub_label={component.sub_label}
                          is_autofill={
                            component.is_autofill ||
                            (selectedIntegrationInComponent && selectedIntegrationInComponent?.id !== component.id)
                          }
                          autoFillValue={productAutoFillLookUp(component, component.lexicon)}
                          selectedValue={values[component.id]}
                          onChange={option => {
                            setValues({ ...values, [component.id]: option });
                            setSelectedProduct({ component, option });
                            if (formLoadedFirstTime.current) {
                              createFormSessions();
                            }
                          }}
                        />
                      )}
                      {component.component_type === 'PAYMENT' && (
                        <Payment
                          {...getFormAttributFonts()}
                          key={component.id}
                          name={component.name}
                          placeholder={component.label}
                          integration={component.integration}
                          selectedProduct={selectedProduct}
                          selectedAddress={selectedAddress}
                          selectedBillingAddress={selectedBillingAddress}
                          backgroundColor={getFormAttributeValue('color')?.button}
                          setPaymentDetails={setPaymentDetails}
                          showBillingAddress={showBillingAddress}
                          onSubmitForm={onSubmitForm}
                          api_key={api_key}
                        />
                      )}
                    </div>
                  </>
                ),
            )}
          </div>
        ))}
      </div>
    );
  };

  const addressWidget = widget => {
    const isContactAddress = widget.components?.some(c => c.lexicon?.includes('contact.address'));
    const addressComponent = widget?.components?.find(data => data?.component_type === 'ADDRESS') || null;
    const newBillingComponents = widget.components?.map(c => ({
      ...c,
      id: `${c.id}-billing`,
      name: c.component_type === 'ADDRESS' ? 'Billing Address' : c.name,
      isBillingAddressComponent: true,
      lexicon: c.lexicon?.includes('contact.address')
        ? `contact.billing_address.${c.lexicon.split('contact.address.')[1]}`
        : c.lexicon,
    }));

    if (isContactAddress) {
      return (
        <div className="flex-column mt-4">
          <label className="medium-text mb-1">Billing address</label>
          <Checkbox
            key={widget.id}
            {...getFormAttributFonts()}
            name={addressComponent?.name ? `Same as ${addressComponent?.name}` : 'Same as shipping address'}
            checked={!showBillingAddress[widget.id]}
            onChange={value => {
              setShowBillingAddress({ ...showBillingAddress, [widget.id]: !value });
            }}
          />
          {showBillingAddress[widget.id] && formWidgets({ ...widget, components: newBillingComponents })}
        </div>
      );
    }
  };

  if (formLoading) {
    return <Loader height={60} />;
  }

  return (
    <Fragment>
      {!fromLaunch && (
        <div className="z-index-10 w-full fixed-header">
          <UserHeader fromFormDetails={true} />
          {formSession?.code && (
            <div className={`flex items-center justify-center w-full session-code-container`}>
              <label className="light-text lighter-text">
                Session code: <span className="regular-text">{formSession?.code}</span>
              </label>
            </div>
          )}
        </div>
      )}
      <FormDetailWrapper
        fromLaunch={fromLaunch}
        sessionCode={formSession?.code}
        className="flex-column flex-1 relative"
        style={{ backgroundColor: getFormAttributeValue('background')?.color }}>
        {!selectedFormPanel?.is_confirmation_panel && getFormAttributeValue('progress_display') === 'TOP' && (
          <ProgressBar
            currentStep={formPanel?.filter(p => !p.is_confirmation_panel).findIndex(f => f.id === selectedFormPanel.id)}
            totalSteps={formPanel?.filter(p => !p.is_confirmation_panel).length}
            formPanel={formPanel?.filter(p => !p.is_confirmation_panel)}
            changeSelectedPanel={changeSelectedPanel}
            isTop
          />
        )}
        <div
          className="card form-wrapper mt-8 flex-column"
          style={{
            minHeight: getFormAttributeValue('height'),
            width: getFormAttributeValue('panel_width'),
            backgroundColor: selectedFormPanel?.panel_color || getFormAttributeValue('color')?.page || '#FFFFFF',
          }}>
          <div className="form-content flex-1">
            {widgetsLoading || isFormSubmitting ? (
              <Loader height={48} />
            ) : (
              formWidget?.map(widget => (
                <>
                  {formWidgets(widget)}
                  {addressWidget(widget)}
                </>
              ))
            )}
          </div>
          {!selectedFormPanel?.is_confirmation_panel && (
            <div
              className="flex col-gap-10 form-action-wrapper"
              style={{ borderColor: getFormAttributeValue('color')?.button || '#8927EF' }}>
              {formPanel
                ?.filter(p => !p.is_confirmation_panel)
                ?.slice()
                .sort((a, b) => b.priority - a.priority)
                .find(f => f.priority < selectedFormPanel.priority) && (
                <Button
                  size="large"
                  width="100%"
                  borderRadius="16px"
                  label={'Previous'}
                  disabled={isFormSubmitting}
                  className={`${isFormSubmitting && 'disabled'}`}
                  borderColor={getFormAttributeValue('color')?.button || '#8927EF'}
                  bgColor={getFormAttributeValue('color')?.button || '#8927EF'}
                  color="#ffffff"
                  onClick={() =>
                    changeSelectedPanel(
                      formPanel
                        ?.slice()
                        .sort((a, b) => b.priority - a.priority)
                        .find(f => f.priority < selectedFormPanel.priority),
                      true,
                    )
                  }
                />
              )}
              {formPanel?.filter(p => !p.is_confirmation_panel).find(f => f.priority > selectedFormPanel.priority) ? (
                <Button
                  size="large"
                  width="100%"
                  borderRadius="16px"
                  label={'Next'}
                  disabled={isFormSubmitting}
                  className={`${isFormSubmitting && 'disabled'}`}
                  borderColor={getFormAttributeValue('color')?.button || '#8927EF'}
                  bgColor={getFormAttributeValue('color')?.button || '#8927EF'}
                  color="#ffffff"
                  onClick={() => changeSelectedPanel(formPanel.find(f => f.priority > selectedFormPanel.priority))}
                />
              ) : (
                !selectedFormPanel?.submit_onpayment && (
                  <Button
                    size="large"
                    width="100%"
                    borderRadius="16px"
                    label={'Submit'}
                    disabled={isFormSubmitting}
                    className={`${isFormSubmitting && 'disabled'}`}
                    borderColor={getFormAttributeValue('color')?.button || '#8927EF'}
                    bgColor={getFormAttributeValue('color')?.button || '#8927EF'}
                    color="#ffffff"
                    onClick={() =>
                      onSubmitForm(
                        paymentDetails.contactId || '',
                        paymentDetails.external_reference || '',
                        paymentDetails.subscription_id || '',
                        true,
                      )
                    }
                  />
                )
              )}
            </div>
          )}
          {renderWatermark(
            selectedFormPanel,
            formPanel?.findIndex(f => f.id === selectedFormPanel.id),
            formPanel,
          )}
        </div>
        {!selectedFormPanel?.is_confirmation_panel && getFormAttributeValue('progress_display') === 'BOTTOM' && (
          <ProgressBar
            currentStep={formPanel?.filter(p => !p.is_confirmation_panel).findIndex(f => f.id === selectedFormPanel.id)}
            totalSteps={formPanel?.filter(p => !p.is_confirmation_panel).length}
            formPanel={formPanel?.filter(p => !p.is_confirmation_panel)}
            changeSelectedPanel={changeSelectedPanel}
          />
        )}
        {!selectedFormPanel?.is_confirmation_panel && (
          <ProgressSteps
            currentStep={formPanel
              ?.filter(p => !p.is_confirmation_panel)
              ?.findIndex(f => f.id === selectedFormPanel.id)}
            totalSteps={formPanel?.filter(p => !p.is_confirmation_panel).length}
            bgColor={getFormAttributeValue('field_accent_color') || '#FFFFFF'}
          />
        )}
      </FormDetailWrapper>
    </Fragment>
  );
};

export default FormDetail;
