import axios from 'axios';
import i18next from 'i18next';
import uuid from 'react-uuid';
import { addToast } from '../features/toastSlice';

let store;

export const injectStoreLaunch = _store => {
  store = _store;
};

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  timeout: 10000,
  params: {},
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const { code } = error || {};

    if (code === 'ECONNABORTED' || code === 'ERR_NETWORK') {
      const { dispatch } = store;
      dispatch(
        addToast({
          error: true,
          text: i18next.t('OFFLINE_OR_POOR_WIFI'),
          id: uuid(),
        }),
      );
    }
    return Promise.reject(error);
  },
);

instance.interceptors.request.use(
  async function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

export default instance;
